<script setup>
/* eslint-disable */
import { ref, watch, onMounted } from "vue";
import Slider from "@vueform/slider";
import Swal from "sweetalert2";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import animationData from "@/components/widgets/msoeawqm.json";
import i18n from "../../i18n";
import store from "@/state/store";
import router from "../../router/index";
import axios from "axios";
import categories from "@/components/categories"

String.prototype.slugify = function () {
  var text = this;
  var trMap = {
    çÇ: "c",
    ğĞ: "g",
    şŞ: "s",
    üÜ: "u",
    ıİ: "i",
    öÖ: "o",
  };
  for (var key in trMap) {
    text = text.replace(new RegExp("[" + key + "]", "g"), trMap[key]);
  }
  return text
    .replace(/[^-a-zA-Z0-9\s]+/gi, "")
    .replace(/\s/gi, "-")
    .replace(/[-]+/gi, "-")
    .toLowerCase();
};

const { t } = i18n.global;
const title = t("Ürünler");

const loading = ref(false)

const items = [
  {
    text: "Elektro Portal",
    href: "/",
  },
  {
    text: t("Ürünler"),
    active: true,
  },
];

const pages = ref([]);
const page = ref(1);
const perPage = ref(10);

function paginate(productsData) {
  let from = page.value * perPage.value - perPage.value;
  let to = page.value * perPage.value;
  return productsData.slice(from, to);
}

function setPages(data) {
  page.value = 1
  pages.value = []
  let numberOfPages = Math.ceil(data.length / perPage.value);
  for (let index = 1; index <= numberOfPages; index++) {
    pages.value.push(index);
  }
}

const Kategoriler = ref({})

axios.get(process.env.VUE_APP_B2B_API_URL+'category/getCategories').then((data) => {
    Kategoriler.value = data['data']
}).catch((error) => {
  console.log('Bir hata oluştu')
  console.log(error)
})

const sliderCustomzie = [1000, 3000];

const searchQuery = ref("")
const brandSearch = ref("")
const Markalar = ref([])
const Brands = ref([])
axios.get(process.env.VUE_APP_B2B_API_URL+'brand/getBrands').then((data) => {
    Brands.value = data['data']
    Markalar.value = Brands.value
}).catch((error) => {
  console.log('Bir hata oluştu')
  console.log(error)
})

const MarkaLimit = ref(5);

function LoadBrand() {
  let AddBrandLimit = 5;
  if (Markalar.value.length - MarkaLimit.value < 5)
    AddBrandLimit = Markalar.value.length - MarkaLimit.value;
  MarkaLimit.value = MarkaLimit.value + AddBrandLimit;
}

const defaultOptions = {
  animationData: animationData,
};

const AllproductsData = ref([]);

const productsData = ref([])

function minusValue(index) {
  if (productsData.value[index].value > 1) productsData.value[index].value--;
}

function plusValue(index) {
  productsData.value[index].value++;
}
function addCartProduct(index) {
  store.commit("cart/addProduct", productsData.value[((page.value-1)*perPage.value) + index]);
  Swal.fire({
    title: t("Sepete Eklendi!"),
    html: t("Ürün başarıyla sepete eklendi."),
    icon: "success",
    confirmButtonText: t("Sepete Git"),
    cancelButtonText: t("Alışverişe Devam Et"),
    focusCancel: true,
    showCancelButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      router.push({ path: "/sepet" });
    }
  });
}

const SelectedFilter = ref({'brand' : [], 'category' : { 'id' : '', 'name' : ''}})

function SelectBrand(index){
  SelectedFilter.value['brand'].push({key: Markalar.value[index].markaid, value: Markalar.value[index].marka })
  Brands.value.splice(Brands.value.findIndex((brand) => brand.marka == Markalar.value[index].marka), 1)
  Markalar.value.splice(index, 1)
}

function RemoveFilter(index){
  Brands.value.push({ markaid: SelectedFilter.value['brand'][index].key, marka: SelectedFilter.value['brand'][index].value})
  if(SelectedFilter.value['brand'][index].value.toUpperCase().includes(brandSearch.value.toUpperCase())){
    Markalar.value.push({ markaid: SelectedFilter.value['brand'][index].key, marka: SelectedFilter.value['brand'][index].value})
  }
  SelectedFilter.value['brand'].splice(index, 1)
}

function RemoveCategory(){
  SelectedFilter.value['category']['name'] = ''
}

function searchProducts(){
  loading.value = true
  let brand = ''
  if(SelectedFilter.value.brand.length > 0){
    brand = JSON.stringify(SelectedFilter.value.brand)
  }
  // console.log(SelectedFilter.value.category.id)
  let formData = new FormData();
  formData.append('name', searchQuery.value)
  formData.append('category', SelectedFilter.value['category']['id'])
  formData.append('brand', brand)
  axios.post(process.env.VUE_APP_B2B_API_URL+'product/getProduct', formData).then((data) => {
      if (data.data['error'] != undefined){
        loading.value = false
      } else {
        productsData.value = []
        data.data.forEach(product => {
          productsData.value.push({
            id: product.material,
            image:
              "https://elektromarketim.com/"+product.imgURL,
            name: product.isim,
            productCode: product.vendrefnum,
            brand: product.markaidadi,
            category: product.stext,
            listePrice: product.liste,
            price: product.webfiyat,
            lcurrency: product.lcurrency,
            value: 1,
            quantity: 1
          })
        });
        loading.value = false
        setPages(productsData.value);
      }
  }).catch((error) => {
    console.log('Bir hata oluştu')
    console.log(error)
  })
}

watch(
  () => SelectedFilter,
  (newValue) => {
    productsData.value = []
    pages.value = []
    newValue.value['brand'].forEach(filter => {
      let searchPro = AllproductsData.value.find((product) => (product.brand==filter.value && product.category == SelectedFilter.value['category']['name']))
      if( searchPro != undefined){
        productsData.value.push(searchPro)
      }
      console.log(filter.value)
    })
  },
  { deep: true }
)
watch(
  () => brandSearch,
  (query) => {
    Markalar.value = Brands.value.filter((brand) => brand.marka.toUpperCase().includes(query.value.toUpperCase()))
  },
  { deep: true }
)

function callback(x, y){
  SelectedFilter.value['category']['id'] = x
  SelectedFilter.value['category']['name'] = y
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-3 col-lg-4">
        <div class="card">
          <div class="accordion accordion-flush">
            <div class="card-body border-bottom">
              <p class="text-muted text-uppercase fs-12 fw-medium mb-2">
                {{ $t("Kategoriler") }}
              </p>
              <categories :data="Kategoriler" :main="true" @selectCategory="callback"></categories>
              
            </div>

            <div class="card-body border-bottom">
              <p class="text-muted text-uppercase fs-12 fw-medium mb-5">
                {{ $t("Fiyat") }}
              </p>

              <div id="product-price-range"></div>
              <Slider v-model="sliderCustomzie" :min="0" :max="5000" />
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingBrands">
                <button
                  class="accordion-button bg-transparent shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseBrands"
                  aria-expanded="true"
                  aria-controls="flush-collapseBrands"
                >
                  <span class="text-muted text-uppercase fs-12 fw-medium"
                    >{{ $t("Markalar") }}</span
                  >
                  <span class="badge bg-success rounded-pill align-middle ms-1"
                    >{{ Markalar.length }}</span
                  >
                </button>
              </h2>

              <div
                id="flush-collapseBrands"
                class="accordion-collapse collapse show"
                aria-labelledby="flush-headingBrands"
              >
                <div class="accordion-body text-body pt-0">
                  <div class="search-box search-box-sm">
                    <input
                      v-model="brandSearch"
                      type="text"
                      class="form-control bg-light border-0"
                      :placeholder="t('Marka Ara...')"
                    />
                    <i class="ri-search-line search-icon"></i>
                  </div>
                  <div class="d-flex flex-column gap-2 mt-3">
                    <div
                      class="form-check"
                      v-for="(Marka, mID) in Markalar.slice(0, MarkaLimit)"
                      :key="Marka.markaid"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        @click="SelectBrand(mID)"
                        :id="Marka.markaid"
                      />
                      <!-- list-group-item-warning -->
                      <label class="form-check-label" :for="Marka.markaid">{{
                        Marka.marka
                      }}</label>
                    </div>

                    <div v-if="Markalar.length - MarkaLimit > 0">
                      <button
                        type="button"
                        @click="LoadBrand"
                        class="
                          btn btn-link
                          text-decoration-none text-uppercase
                          fw-medium
                          p-0
                        "
                      >
                        {{ $t("marka_daha", { count: Markalar.length - MarkaLimit }) }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-9 col-lg-8">
        <div class="card">
          <div class="card-header border-0">
            <div class="row g-4">
              <div class="col-lg-10">
                <div class="d-flex">
                  <div class="search-box ms-2" style="width: 100%">
                    <input
                      type="text"
                      class="form-control"
                      v-model="searchQuery"
                      :placeholder="t('Ürün Ara...')"
                    />
                    <i class="ri-search-line search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div>
                  <button
                    class="btn btn-success"
                    style="width: 100%"
                    @click="searchProducts"
                    ><i class="ri-search-line align-bottom me-1"></i> {{ $t("Ara") }}</button
                  >
                </div>
              </div>
              <div class="col-lg-12">
                <div class="d-flex flex-wrap gap-2">
                  <b-badge variant="success" v-if="SelectedFilter['category']['name'] != ''"><i class="mdi mdi-close" @click="RemoveCategory()"></i>{{ SelectedFilter['category']['name'] }}</b-badge>
                  <b-badge variant="primary" v-for="(Filter, fID) in SelectedFilter['brand']" :key="Filter.key"><i class="mdi mdi-close" @click="RemoveFilter(fID)"></i> {{ Filter.value }}</b-badge>
                </div>
              </div>
            </div>
          </div>
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <ul
                  class="nav nav-tabs-custom card-header-tabs border-bottom-0"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link active fw-semibold"
                      data-bs-toggle="tab"
                      href="#productnav-all"
                      role="tab"
                    >
                      All
                      <span
                        class="
                          badge badge-soft-danger
                          align-middle
                          rounded-pill
                          ms-1
                        "
                        >12</span
                      >
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="tab-content text-muted">
              <div class="tab-pane active" id="productnav-all" role="tabpanel">
                <div class="table-card gridjs-border-none table-responsive" >
                  <div class="d-flex justify-content-end m-3">
                    <div class="pagination-wrap hstack gap-2">
                      <a
                        class="page-item pagination-prev disabled"
                        href="javascript:;"
                        v-if="page > 50"
                        @click="page = 1"
                      >
                        {{ $t("İlk") }}
                      </a>
                      <a
                        class="page-item pagination-prev disabled"
                        href="javascript:;"
                        v-if="page != 1"
                        @click="page--"
                      >
                        {{ $t("Önceki") }}
                      </a>
                      <ul class="pagination listjs-pagination mb-0">
                        <li
                          v-for="(pageNumber, index) in pages.slice(
                            page <= 2 ? page - page : page - 3,
                            page + 2)"
                          :key="index"
                          @click="page = pageNumber"
                          :class="{
                            active: pageNumber == page,
                            disabled: pageNumber == '...',
                          }"
                        >
                          <a class="page" href="javascript:;">{{ pageNumber }}</a>
                        </li>
                      </ul>
                      <a
                        class="page-item pagination-next"
                        href="javascript:;"
                        @click="page++"
                        v-if="page < pages.length"
                      >
                        {{ $t("Sonraki") }}
                      </a>
                      <a
                        class="page-item pagination-next"
                        href="javascript:;"
                        @click="page = pages.length"
                        v-if="page < pages.length - 50 & page > 10"
                      >
                        {{ $t("Son") }}
                      </a>
                    </div>
                  </div>
                  <table class="table table-striped align-middle mb-0" >
                    <thead class="table-light">
                      <tr>
                        <th scope="col" style="width: 55%">{{ $t("Ürün") }}</th>
                        <th scope="col">{{ $t("Adet") }}</th>
                        <th scope="col">{{ $t("Liste Fiyatı") }}</th>
                        <th scope="col">{{ $t("Site Fiyatı") }}</th>
                        <th scope="col">{{ $t("İşlem") }}</th>
                      </tr>
                    </thead>
                    <tbody class="list placeholder-glow" v-if="loading">
                      <tr>
                        <td><span class="placeholder col-12 bg-primary"></span></td>
                        <td><span class="placeholder col-12 bg-primary"></span></td>
                        <td><span class="placeholder col-12 bg-primary"></span></td>
                        <td><span class="placeholder col-12 bg-primary"></span></td>
                        <td><span class="placeholder col-12 bg-primary"></span></td>
                      </tr>
                      <tr>
                        <td><span class="placeholder col-12 bg-secondary"></span></td>
                        <td><span class="placeholder col-12 bg-secondary"></span></td>
                        <td><span class="placeholder col-12 bg-secondary"></span></td>
                        <td><span class="placeholder col-12 bg-secondary"></span></td>
                        <td><span class="placeholder col-12 bg-secondary"></span></td>
                      </tr>
                      <tr>
                        <td><span class="placeholder col-12 bg-success"></span></td>
                        <td><span class="placeholder col-12 bg-success"></span></td>
                        <td><span class="placeholder col-12 bg-success"></span></td>
                        <td><span class="placeholder col-12 bg-success"></span></td>
                        <td><span class="placeholder col-12 bg-success"></span></td>
                      </tr>
                      <tr>
                        <td><span class="placeholder col-12 bg-danger"></span></td>
                        <td><span class="placeholder col-12 bg-danger"></span></td>
                        <td><span class="placeholder col-12 bg-danger"></span></td>
                        <td><span class="placeholder col-12 bg-danger"></span></td>
                        <td><span class="placeholder col-12 bg-danger"></span></td>
                      </tr>
                      <tr>
                        <td><span class="placeholder col-12 bg-info"></span></td>
                        <td><span class="placeholder col-12 bg-info"></span></td>
                        <td><span class="placeholder col-12 bg-info"></span></td>
                        <td><span class="placeholder col-12 bg-info"></span></td>
                        <td><span class="placeholder col-12 bg-info"></span></td>
                      </tr>
                    </tbody>
                    <tbody class="list" v-else>
                      <tr
                        v-for="(data, index) of paginate(productsData)"
                        :key="index"
                      >
                        <td class="id" hidden>
                          <a href="">{{ data.id }}</a>
                        </td>
                        <td data-column-id="product">
                          <span>
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm bg-light rounded p-1">
                                  <img
                                    :src="data.image"
                                    alt=""
                                    class="img-fluid d-block"
                                  />
                                </div>
                              </div>
                              <div class="flex-grow-1">
                                <h5 class="fs-14 mb-1">
                                  <router-link
                                    to="/ecommerce/product-details"
                                    class="text-dark"
                                    >{{ data.name }}
                                  </router-link>
                                </h5>
                                <p class="text-muted mb-0">
                                  {{ $t("Ürün Kodu") }} :
                                  <span class="fw-medium">{{
                                    data.productCode
                                  }}</span>&nbsp;
                                  {{ $t("Marka") }} :
                                  <span class="fw-medium">{{
                                    data.brand
                                  }}</span>
                                </p>
                              </div>
                            </div>
                          </span>
                        </td>
                        <td>
                          <div class="input-step">
                            <button
                              type="button"
                              class="minus"
                              @click="minusValue(index)"
                            >
                              –
                            </button>
                            <input
                              type="number"
                              class="product-quantity"
                              v-model="data.value"
                              min="1"
                              max="100"
                            />
                            <button
                              type="button"
                              class="plus"
                              @click="plusValue(index)"
                            >
                              +
                            </button>
                          </div>
                        </td>
                        <td>
                          {{ $filters.currency(data.listePrice, data.lcurrency) }}
                        </td>
                        <td>
                          {{ $filters.currency(data.price) }}
                        </td>
                        <td>
                          <button
                            class="btn btn-success btn-sm"
                            @click="addCartProduct(index)"
                          >
                            <i
                              class="ri-shopping-cart-line align-bottom me-1"
                            ></i>
                            {{ $t("Sepete Ekle") }}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="d-flex justify-content-end m-3">
                    <div class="pagination-wrap hstack gap-2">
                      <a
                        class="page-item pagination-prev disabled"
                        href="javascript:;"
                        v-if="page > 50"
                        @click="page = 1"
                      >
                        {{ $t("İlk") }}
                      </a>
                      <a
                        class="page-item pagination-prev disabled"
                        href="javascript:;"
                        v-if="page != 1"
                        @click="page--"
                      >
                        {{ $t("Önceki") }}
                      </a>
                      <ul class="pagination listjs-pagination mb-0">
                        <li
                          v-for="(pageNumber, index) in pages.slice(
                            page <= 2 ? page - page : page - 3,
                            page + 2)"
                          :key="index"
                          @click="page = pageNumber"
                          :class="{
                            active: pageNumber == page,
                            disabled: pageNumber == '...',
                          }"
                        >
                          <a class="page" href="javascript:;">{{ pageNumber }}</a>
                        </li>
                      </ul>
                      <a
                        class="page-item pagination-next"
                        href="javascript:;"
                        @click="page++"
                        v-if="page < pages.length"
                      >
                        {{ $t("Sonraki") }}
                      </a>
                      <a
                        class="page-item pagination-next"
                        href="javascript:;"
                        @click="page = pages.length"
                        v-if="page < pages.length - 50 & page > 10"
                      >
                        {{ $t("Son") }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end tab pane -->

              <div class="tab-pane" id="productnav-published" role="tabpanel">
                <div
                  id="table-product-list-published"
                  class="table-card gridjs-border-none"
                ></div>
              </div>
              <!-- end tab pane -->

              <div class="tab-pane" id="productnav-draft" role="tabpanel">
                <div class="py-4 text-center">
                  <div>
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                  </div>

                  <div class="mt-4">
                    <h5>Sorry! No Result Found</h5>
                  </div>
                </div>
              </div>
              <!-- end tab pane -->
            </div>
            <!-- end tab content -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.avatar-sm {
  min-height: 3rem;
  height: auto;
}
</style>